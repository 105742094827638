.home .banner {
  background-image: linear-gradient(143deg, #fbb211, #ffd200);
  height: 50rem;
}
.home .banner .parallax-container {
  height: 50rem;
  position: relative;
}
.home .banner .parallax-images {
  background: url('/assets/img/background/svg/icons/img-illustrations.svg')
    repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .home .banner .parallax-images {
    background-size: 100%;
    height: 200%;
  }
}
@media screen and (max-width: 767px) {
  .home .banner {
    padding-top: 6.4rem;
  }
}
.home .banner .img-banner {
  margin-bottom: 6.4rem;
  margin-top: 6.4rem;
  width: 14.1rem;
}
.home .banner h1 {
  font-size: 2rem;
  margin-bottom: 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (min-width: 499px) {
  .home .banner h1 {
    margin-bottom: 6.4rem;
  }
}
.home .banner .btn {
  font-size: 1.4rem;
  padding: 2.1rem;
  width: 21.7rem;
  margin-bottom: 3rem;
}
.home .banner .arrow {
  display: block;
  margin: auto;
}
@media screen and (min-width: 768px) {
  .home .banner {
    height: 35rem;
  }
  .home .banner .img-banner {
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    width: 16rem;
  }
  .home .banner h1 {
    font-size: 3rem;
    margin-bottom: 2.1rem;
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .home .banner .btn {
    font-size: 1.8rem;
    padding: 1.8rem;
    width: 24.3rem;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .home .banner {
    background-image: linear-gradient(110deg, #f7971e, #ffd200);
    height: 60rem;
  }
  .home .banner .img-banner {
    margin-bottom: 5.1rem;
    margin-top: 6.1rem;
    width: 30rem;
  }
  .home .banner h1 {
    font-size: 5rem;
    margin-left: 5.7rem;
    margin-right: 5.7rem;
  }
  .home .banner h2 {
    color: #fff;
    font-family: 'DINPro-Regular', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 1.6rem;
    margin-bottom: 4rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1300px) {
  .home .banner h2 {
    margin-bottom: 6.3rem;
  }
}
@media screen and (min-width: 992px) {
  .home .banner .btn {
    font-size: 2rem;
    padding: 2.8rem;
    width: 26rem;
    height: 8rem;
    margin-bottom: 2rem;
  }
}

.home {
  .connect {
    padding: 12rem 4rem;

    .left-container {
      position: relative;
      width: 80%;

      .image-outline {
        width: 75%;
        height: 115%;
        top: -5%;
        left: 15%;
        position: absolute;
        border: 0.2rem solid #ffc200;
        border-radius: 0;
      }
      .image-container {
        position: relative;

        img {
          margin-left: 20%;
          max-width: 100%;
          position: relative;
        }
      }
    }
    .description {
      height: 100%;

      em {
        color: #ffc200;
        font-style: normal;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home {
    .connect {
      .left-container {
        .image-outline {
          width: 37%;
          left: 23%;
        }
        .image-container {
          width: 50%;
          margin-left: 25%;
        }
      }
      .description {
        margin-top: 10rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home {
    .connect {
      .description {
        margin-top: 4rem;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .home .studio .values .card {
    margin-bottom: 5rem;
  }
  .home .studio .values .card h3 {
    margin-top: 12rem;
  }
  .home .studio .values .card p {
    line-height: 1.88;
    margin-bottom: 1rem;
  }
  .home .studio .values .card img {
    left: 35%;
    top: 4.2rem;
    width: 9.9rem;
  }
  .home .studio .values .card:nth-child(1) img {
    left: 35%;
  }
}

.home .quote {
  background: linear-gradient(116deg, #f7971e, #ffd200);
  height: 22.7rem;
  position: relative;
  z-index: -1;
}
.home .quote .parallax-container {
  height: 50rem;
  position: relative;
}
.home .quote .parallax-images {
  background: url('/assets/img/background/svg/icons/img-illustrations.svg')
    repeat;
  background-size: 150%;
  height: 100%;
  position: absolute;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .home .quote .parallax-images {
    background-size: 100%;
  }
}
.home .quote .parallax-images {
  background-size: auto;
  height: 600%;
}
.home .quote .quote-left {
  padding-bottom: 1.9rem;
  padding-top: 3rem;
  width: 3rem;
}
.home .quote .quote-right {
  padding-bottom: 3rem;
  padding-top: 1.9rem;
  width: 3rem;
}
.home .quote blockquote {
  color: #fff;
  font-family: 'Montserrat-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: none;
}
.home .quote blockquote strong {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
}
@media screen and (min-width: 768px) {
  .home .quote {
    height: 25rem;
  }
  .home .quote blockquote {
    font-size: 2rem;
    padding: 10rem 5.5rem;
  }
  .home .quote .quote-left {
    margin-left: 6.2rem;
    width: 5rem;
  }
  .home .quote .quote-right {
    margin-right: 6.2rem;
    width: 5rem;
  }
}
@media screen and (min-width: 992px) {
  .home .quote {
    height: 29rem;
  }
  .home .quote blockquote {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1000px) {
  .home .quote h2 {
    padding-top: 12rem;
  }
}
@media screen and (min-width: 992px) {
  .home .quote .quote-left {
    margin-left: 6em;
    width: 7.9rem;
  }
  .home .quote .quote-right {
    margin-right: 10.9rem;
    width: 7.9rem;
  }
}

.home .team-home {
  margin-bottom: 9rem;
  margin-top: 4.5rem;
}
.home .team-home h2 {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  margin-left: 1.2rem;
  text-transform: uppercase;
}
.home .team-home h2 em {
  color: #ffc200;
  font-style: normal;
}
.home .team-home hr {
  border: solid 0.05rem #fbb211;
  margin-bottom: 0;
  margin-left: 1.3rem;
  width: 1.6rem;
}
.home .team-home .team-carousel {
  padding-bottom: 4rem;
  padding-top: 2rem;
}
.home .team-home .team-carousel .desc h2,
.home .team-home .team-carousel .desc p {
  color: transparent;
}
.home .team-home .team-carousel img {
  filter: brightness(50%);
  margin-top: 5rem;
  width: 10.8rem;
}
.home .team-home .team-carousel .outside {
  border: 0;
  position: relative;
}
.home .team-home .team-carousel .inside {
  border: transparent;
}
.home .team-home .team-carousel .slick-slide {
  width: 10.8rem;
  z-index: -1;
}
.home .team-home .team-carousel .slick-center .outside {
  margin-left: -4rem;
  z-index: 2;
}
.home .team-home .team-carousel .slick-center .inside {
  border: solid 0.1rem #ffc200;
  border-radius: 0;
  height: 41.2rem;
  margin: -2rem 2rem 10rem;
  position: absolute;
  top: 3rem;
  width: 18.39rem;
  z-index: 2;
}
.home .team-home .team-carousel .slick-center img {
  filter: brightness(100%);
  margin-top: 0;
  width: 18.4rem;
}
.home .team-home .team-carousel .slick-center .desc {
  width: 20rem;
}
.home .team-home .team-carousel .slick-center .desc h2,
.home .team-home .team-carousel .slick-center .desc p {
  margin-left: 3rem;
  margin-right: 1.6rem;
  padding-left: 0;
}
.home .team-home .team-carousel .slick-center .desc h2 {
  color: #f7971e;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  margin-top: 0.7rem;
  text-transform: none;
}
.home .team-home .team-carousel .slick-center .desc p {
  color: #4a4a4a;
  font-family: 'Montserrat-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  .home .team-home {
    margin-bottom: 1.5rem;
  }
  .home .team-home h2 {
    font-size: 3rem;
    margin-left: 6.3rem;
  }
  .home .team-home hr {
    border: solid 0.15rem #fbb211;
    margin-left: 6.3rem;
    margin-top: 2.4rem;
    width: 3rem;
  }
  .home .team-home .team-carousel {
    padding-bottom: 0;
  }
  .home .team-home .team-carousel img {
    margin-top: 5rem;
    width: 14.4rem;
  }
  .home .team-home .team-carousel .slick-slide {
    width: 14.4rem;
  }
  .home .team-home .team-carousel .slick-center .outside {
    margin-left: -4rem;
  }
  .home .team-home .team-carousel .slick-center .inside {
    border: solid 0.2rem #ffc200;
    height: 45rem;
    width: 21.2rem;
  }
  .home .team-home .team-carousel .slick-center img {
    width: 20.9rem;
  }
  .home .team-home .team-carousel .slick-center .desc h2,
  .home .team-home .team-carousel .slick-center .desc p {
    margin-left: 4rem;
    margin-right: 0;
  }
  .home .team-home .team-carousel .slick-center .desc h2 {
    font-size: 2rem;
  }
  .home .team-home .team-carousel .slick-center .desc p {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 992px) {
  .home .team-home {
    height: 70rem;
    margin-bottom: 15rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home {
    height: 90rem;
  }
}
@media screen and (min-width: 992px) {
  .home .team-home h2 {
    font-size: 5rem;
    margin-left: 10rem;
  }
  .home .team-home hr {
    margin-left: 10.2rem;
    width: 6rem;
  }
  .home .team-home .team-carousel .desc h2,
  .home .team-home .team-carousel .desc p {
    color: transparent;
    margin-left: 3rem;
    padding-left: 0;
  }
  .home .team-home .team-carousel .tilter {
    display: none;
  }
  .home .team-home .team-carousel *:hover,
  .home .team-home .team-carousel *:focus,
  .home .team-home .team-carousel *:active,
  .home .team-home .team-carousel *:visited {
    outline: 0;
  }
  .home .team-home .team-carousel svg {
    display: none;
  }
  .home .team-home .team-carousel img {
    padding-top: 6rem;
    width: 26.6rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home .team-carousel img {
    width: 35rem;
  }
}
@media screen and (min-width: 992px) {
  .home .team-home .team-carousel .slick-center .tilter {
    display: block;
    height: 20rem;
    padding-top: 11rem;
    perspective: 1000px;
  }
  .home .team-home .team-carousel .slick-center svg {
    display: block;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home .team-carousel .slick-center svg .team-rectangle {
    d: path('M 1 1 h 449 v 790 h -449 V 1 Z');
  }
}
@media screen and (min-width: 992px) {
  .home .team-home .team-carousel .slick-center img {
    padding-top: 5rem;
    width: 34.9rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home .team-carousel .slick-center img {
    width: 45rem;
  }
}
@media screen and (min-width: 992px) {
  .home .team-home .team-carousel .slick-center .tilter * {
    pointer-events: none;
  }
  .home .team-home .team-carousel .slick-center .tilter-figure > * {
    transform: translateZ(0px);
    /* Force correct stacking order */
  }
  .home .team-home .team-carousel .slick-center .smooth .tilter-figure,
  .home .team-home .team-carousel .slick-center .smooth .tilter-deco-lines div {
    transition: transform 0.2s ease-out;
  }
  .home .team-home .team-carousel .slick-center .tilter-figure {
    position: relative;
  }
  .home .team-home .team-carousel .slick-center .tilter-deco {
    height: 80rem;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 50rem;
    z-index: 5;
  }
  .home .team-home .team-carousel .slick-center .tilter-deco-lines {
    fill: none;
    left: 2rem;
    stroke: #ffc200;
    stroke-width: 2px;
    top: -63rem;
    z-index: 5;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home .team-carousel .slick-center .tilter-deco-lines {
    top: -81rem;
  }
}
@media screen and (min-width: 992px) {
  .home .team-home .team-carousel .slick-center .desc {
    padding-left: 1.3rem;
    position: relative;
    top: -10rem;
    width: 30rem;
  }
  .home .team-home .team-carousel .slick-center .desc h2 {
    margin-top: 1rem;
  }
  .home .team-home .team-carousel .slick-slide {
    width: 26.6rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 2370px) {
  .home .team-home .team-carousel .slick-slide {
    width: 35rem;
  }
}

@media screen and (max-width: 767px) {
  .home .expertise {
    margin-top: -15rem;
  }
}

.home .expertise .description {
  display: none;
}

.home .messages {
  background: #fbfbfb;
  height: 37rem;
  margin-top: 4rem;
}
.home .messages h2 {
  font-family: 'DINPro-Medium', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  padding: 3.3rem 1.5rem;
}
.home .messages h3 {
  font-family: 'DINPro-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
}
.home .messages .carousel-indicators {
  bottom: -5rem;
  position: absolute;
  top: 30rem;
}
.home .messages .carousel-indicators li {
  background: #d8d8d8;
  border-radius: 50%;
  height: 0.8rem;
  margin: 1rem;
  width: 0.8rem;
}
.home .messages .carousel-indicators li.active {
  background: #ffa100;
}
@media screen and (min-width: 768px) {
  .home .messages {
    height: 36.1rem;
    margin-top: 6rem;
  }
  .home .messages h2 {
    font-size: 3rem;
    line-height: 4rem;
    padding: 3rem 6.2rem;
  }
  .home .messages h3 {
    margin-top: 0.5rem;
  }
  .home .messages .carousel-indicators li {
    height: 1rem;
    width: 1rem;
  }
}
@media screen and (min-width: 992px) {
  .home .messages {
    height: 34rem;
    margin-top: 10.2rem;
  }
  .home .messages h2 {
    padding: 4.5rem 10.8rem 3.5rem;
  }
}
@media screen and (min-width: 992px) and (min-width: 1123px) {
  .home .messages h2 {
    padding-top: 7.4rem;
  }
}
@media screen and (min-width: 992px) {
  .home .messages h3 {
    font-size: 1.6rem;
  }
  .home .messages .carousel-indicators {
    top: 28rem;
  }
}

.home .projects .carousel {
  background-image: linear-gradient(
    to bottom,
    #fefefe,
    #fafafa 14%,
    #e3e3e3 59%,
    #f8f8f8 69%,
    #f2f2f2
  );
}
.home .projects .carousel .arrow-next {
  transform: rotate(-180deg);
}
.home .projects .carousel {
  $carousel-space: 4rem;

  .carousel-inner {
    .carousel-item-img {
      height: 20vh;
      margin-top: $carousel-space;
      @media screen and (min-width: 767px) {
        height: 40vh;
        margin-top: calc(#{$carousel-space} * 1.5);
      }
    }
  }

  .carousel-indicators {
    position: static; // override bootstrap position: absolute
    margin: 0;

    .logo-img {
      margin: 0;
      width: 50%;
      height: unset;
      padding: 4rem;

      @media screen and (max-width: 767px) {
        &.last {
          width: 100%;
        }
      }

      &.active img,
      &:hover img {
        filter: grayscale(0%);
      }

      img {
        transition: all 0.3s ease;
        filter: grayscale(100%);
        max-height: 4.6rem;
        max-width: 11.6rem;
      }
    }
  }

  .btn {
    transition: all 0.3s ease;
    font-size: 1.4rem;
    background: transparent;
    border: solid 1px #000000;
    padding: 1.7rem 1.4rem;
    margin: $carousel-space 0;

    @media screen and (min-width: 767px) {
      margin: calc(#{$carousel-space} * 1.5) 0;
    }

    p {
      color: #000000;
      margin-bottom: 0;
    }

    &:hover {
      background-color: #000;

      p {
        color: #fafafa;
      }
    }
  }
}
